import { tokenLogo } from '@/shared/assets/tokens/logo';
import { NotFound } from '../../assets/token-logos';
import { type Token } from '../../assets/tokens';
import { formatIpfsUrl } from '../../utils/strings';

export const TokenLogo = ({
  token,
  className,
  height = 34,
  width = 34,
}: {
  token: Pick<Token, 'symbol' | 'logo'>;
  className?: string;
  height?: number;
  width?: number;
}) => {
  if (!tokenLogo[token.symbol]) {
    return (
      <div className={className} style={{ height: `${height}px`, width: `${width}px` }}>
        {typeof token.logo === 'string' && token.logo.length > 1 ? (
          <img className="rounded-[100%]" src={formatIpfsUrl(token.logo)} alt="" />
        ) : (
          <NotFound width={width} height={height} />
        )}
      </div>
    );
  }
  return (
    <div className={className} style={{ height: `${height}px`, width: `${width}px` }}>
      {tokenLogo[token.symbol]({ width, height })}
    </div>
  );
};
