import { BtcLogo, DotLogo, EthLogo, FlipLogo, MaticLogo, UsdcLogo, UsdtLogo } from '../token-logos';
import { type SupportedTokenSymbol, type Token } from '.';

export const tokenLogo: Record<
  Token['symbol'],
  (props?: React.SVGProps<SVGSVGElement>) => JSX.Element
> = {
  BTC: BtcLogo,
  tBTC: BtcLogo,
  ETH: EthLogo,
  sETH: EthLogo,
  bETH: EthLogo,
  FLIP: FlipLogo,
  sFLIP: FlipLogo,
  bFLIP: FlipLogo,
  USDC: UsdcLogo,
  sUSDC: UsdcLogo,
  bUSDC: UsdcLogo,
  USDT: UsdtLogo,
  sUSDT: UsdtLogo,
  bUSDT: UsdtLogo,
  DOT: DotLogo,
  pDOT: DotLogo,
  MATIC: MaticLogo,
} satisfies Record<SupportedTokenSymbol, (props?: React.SVGProps<SVGSVGElement>) => JSX.Element>;
